define("discourse/plugins/discourse-data-explorer/discourse/models/query", ["exports", "discourse/models/rest", "discourse-common/lib/get-url"], function (_exports, _rest, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Query extends _rest.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "params", {});
      this.param_info?.resetParams();
    }
    get downloadUrl() {
      return (0, _getUrl.default)(`/admin/plugins/explorer/queries/${this.id}.json?export=1`);
    }
    get hasParams() {
      return this.param_info.length;
    }
    resetParams() {
      const newParams = {};
      const oldParams = this.params;
      this.param_info.forEach(pinfo => {
        const name = pinfo.identifier;
        if (oldParams[pinfo.identifier]) {
          newParams[name] = oldParams[name];
        } else if (pinfo["default"] !== null) {
          newParams[name] = pinfo["default"];
        } else if (pinfo["type"] === "boolean") {
          newParams[name] = "false";
        } else if (pinfo["type"] === "user_id") {
          newParams[name] = null;
        } else if (pinfo["type"] === "user_list") {
          newParams[name] = null;
        } else {
          newParams[name] = "";
        }
      });
      this.params = newParams;
    }
    updateProperties() {
      const props = this.getProperties(Query.updatePropertyNames);
      if (this.destroyed) {
        props.id = this.id;
      }
      return props;
    }
    createProperties() {
      if (this.sql) {
        // Importing
        return this.updateProperties();
      }
      return this.getProperties("name");
    }
  }
  _exports.default = Query;
  _defineProperty(Query, "updatePropertyNames", ["name", "description", "sql", "user_id", "created_at", "group_ids", "last_run_at"]);
});